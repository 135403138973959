body {
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: serif;
  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: black;
  }
  &::-webkit-scrollbar-track{
    background: rgb(207, 207, 207);
  }
}

